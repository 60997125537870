import axios from "axios";
import Cookies from "js-cookie";

class BreedDTO {
  constructor(public breedId?: string, public name?: string) {}

  static fromJson(json: any | null) {
    return new BreedDTO(json?.breed_id, json?.name);
  }
}

class PartialPetDTO {
  constructor(
    public petId: number,
    public name: string,
    public dob?: string,
    public gender?: string,
    public weight?: number,
    public neutered?: string,
    public breed?: BreedDTO,
    public activityLevel?: string
  ) {}

  static fromJson(json: any) {
    return new PartialPetDTO(
      json.pet_id,
      json.name,
      json.dob,
      json.gender,
      json.weight,
      json.neutered,
      BreedDTO.fromJson(json.breed),
      json.activity_level
    );
  }
}

class PreregistrationCustomerService {
  public getCustomersPartialPets() {
    const url = `/api/vets/prototype/customer/partial-pets`;
    return axios
      .get(url, { headers: { Authorization: Cookies.get("preRegistrationToken") as string } })
      .then(response =>
        response.data.resp.map((obj: any) => {
          return PartialPetDTO.fromJson(obj);
        })
      );
  }

  public getCustomersPartialPet(petId: number) {
    const url = `/api/vets/prototype/customer/partial-pets/${petId}`;
    return axios
      .get(url, { headers: { Authorization: Cookies.get("preRegistrationToken") as string } })
      .then(response => PartialPetDTO.fromJson(response.data.resp));
  }
}

const preregistrationCustomerService = new PreregistrationCustomerService();
export default preregistrationCustomerService;
