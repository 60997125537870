<template>
  <v-card class="v2p-card">
    <v-card-text class="v2p-card-text">
      <h2 class="v2p-form-title">PET DETAILS</h2>
      <p>Which dog would you like to update the details for?</p>
      <v-form v-model="formValid" @submit.prevent="handleSubmit">
        <v-radio-group v-model="selectedPet" mandatory>
          <p>Dog</p>
          <v-radio v-for="pet in pets" :key="pet.petId" :value="pet.petId">
            <template v-slot:label>
              <b>{{ pet.name }}</b> {{ getLabelForPet(pet) }}
            </template>
          </v-radio>
          <p>or</p>
          <v-radio label="Add new pet" :value="0" />
        </v-radio-group>
        <v-btn class="v2p-confirm-button-orange" type="submit" :disabled="!formValid">
          Continue
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import PreregistrationCustomerService from "@/services/customer/preregistrationCustomer.service";
import { RouteNames } from "@/router/routeNames";

export default {
  name: "PetSelection",
  data() {
    return {
      selectedPet: null,
      pets: [],
      formValid: false
    };
  },
  async mounted() {
    this.pets = await PreregistrationCustomerService.getCustomersPartialPets();
  },
  methods: {
    getLabelForPet(pet) {
      let label = ``;
      if (pet.breed.name) label += `, ${pet.breed.name}`;
      if (pet.dob) label += `, ${pet.dob}`;
      return label;
    },
    async handleSubmit() {
      let nextRoute = { name: RouteNames.CREATE_PET };
      if (this.selectedPet !== 0) {
        nextRoute = { name: RouteNames.CREATE_PET, params: { petId: this.selectedPet } };
      }
      await this.$router.push(nextRoute);
    }
  }
};
</script>

<style scoped lang="scss">
.v-radio {
  ::v-deep .v-label {
    margin: auto 0 !important;
  }
}
</style>
